<template>
  <div class="MarBox">
    <Header></Header>
    <div class="linkBox">
      <div @click="backHome()" class="linkBoxItem">首页</div>
      <div class="linkBoxItem">意见反馈</div>
    </div>
    <div class="headBox">
      <p @click="changePage" class="headBox-p">{{ subPage ? '我的反馈' : '去反馈' }}</p>
    </div>
    <div class="bodyBox" v-show="subPage">
      <!--      <div class="topBox"><p>意见反馈</p></div>-->
      <div class="imgBox">
        <img src="@/assets/backs.png" class="imgBox_img" alt=""/>
      </div>
      <div class="formBox">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="反馈门店" prop="shopId">
            <el-select v-model="form.shopId" placeholder="选择反馈门店" style="width: 100%">
              <el-option-group
                v-for="group in shopList"
                :key="group.shopRegionName"
                :label="group.shopRegionName">
                <el-option
                  v-for="item in group.shopList"
                  :key="item.id"
                  :label="item.shopName"
                  :value="item.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="反馈类型" prop="feedbackTypeId">-->
          <!--            <el-select v-model="form.feedbackTypeId" placeholder="请选择反馈类型" style="width: 100%">-->
          <!--              <el-option-->
          <!--                v-for="item in typeList"-->
          <!--                :key="item.id"-->
          <!--                :label="item.name"-->
          <!--                :value="item.id">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="姓名" prop="name">-->
          <!--            <el-input v-model="form.name"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="手机号" prop="tel">
            <el-input v-model="form.tel" type="tel"></el-input>
          </el-form-item>
          <el-form-item label="意见" prop="content">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="上传图片">
            <img-upload-list
              :action="imgUrls"
              accept="image/png, image/jpeg"
              :showUploadList="true"
              :fileList="fileList"
              :maxFile="3"
              :beforeUpload="coverImgListBeforeUpload"
              @success="coverImgListOnSuccess"
              :handleChange="coverImgListChange"
              :preView="handlePreview"
            />
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
          </el-form-item>
          <div class="subBtnBox">
            <el-button type="primary" @click="onSubmit" class="subBtnBox_btn">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bodyBox" v-show="!subPage">
      <!--      <div class="topBox"><p>我的反馈</p></div>-->
      <div class="formBox1">
        <el-form ref="form1" :model="form1" :rules="rules1" :inline="true" label-width="80px">
          <el-form-item label="手机号" prop="tel">
            <el-input v-model="form1.tel" type="tel" style="width:70%;"></el-input>
            <el-button type="primary" @click="onSubmit1" style="margin-left: 10px;">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="recordList.length>0">
        <div class="listBox" v-for="(r,index) in recordList" :key="index">
          <div class="listBox_item" v-if="r.shopName">反馈门店：
            <el-tag size="mini">{{ r.shopName }}</el-tag>
          </div>
          <div class="listBox_item">反馈时间：{{ r.createTime }}</div>
          <div class="listBox_item">意见：{{ r.content }}</div>
          <div class="listBox_imges" v-if="r.havImg">
            <div>反馈图片：</div>
            <div v-for="(item,indexs) in r.attachment.imgList" :key="indexs+'B'">
              <el-image :src="item" style="width: 50px;height: 50px;margin:3px;" @click="handlePreview1(item)"
                        lazy></el-image>
            </div>
          </div>
          <div class="listBox_item" v-if="r.reply">商家回复：<p
            style="color: #000000;background-color: #fafafa;padding: 5px;">{{ r.reply }}</p></div>
          <div class="listBox_item" v-if="r.replyTime">回复时间：{{ r.replyTime }}</div>
        </div>
        <div style="width: 100%;text-align: center;margin: 20px auto;">
          <el-pagination
            small
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageData.pageSize"
            :current-page="pageData.pageNum"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div v-if="recordList.length<1" class="nullTip">暂无数据</div>
    </div>
    <div class="backBox" v-show="!subPage" @click="changePage">返回</div>
    <div class="footBox"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import {get, bascUrl, postFrom, postJson} from "@/utils/request";
import {getUrlParams} from "@/utils/tools";
import ImgUploadList from "@/components/UploadImageList.vue";
import deImg from "@/assets/index/pc-b.jpg";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "feedback",
  components: {ImgUploadList},
  data() {
    return {
      form: {
        tel: '',
        content: '',
        shopId: ''
      },
      rules: {
        tel: [
          {required: true, message: '请输入联系方式', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入反馈建议', trigger: 'blur'}
        ],
        shopId: [
          {required: false, message: '请选择反馈门店', trigger: 'change'}
        ],
      },
      form1: {
        tel: ''
      },
      rules1: {
        tel: [
          {required: true, message: '请输入要查询的手机号', trigger: 'blur'}
        ],
      },
      typeList: [],
      shopList: [],
      fileList: [],
      coverImgCount: 0,
      previewVisible: false,
      previewImage: '',
      imgSize: 2,
      imgUrls: '/file/upImg',
      subPage: true,
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      recordList: []
    }
  },
  created() {
    // console.log('页面参数', getUrlParams());
    this.imgUrls = bascUrl() + this.imgUrls
    this.getWebInfo()
    if (getUrlParams()) {
      this.getBackType(getUrlParams().id)
    }
  },
  methods: {
    async getWebInfo() {
      await get("/api/webPage/getWebPageInfo").then(res => {
        if (res && res.data) {
          let backImg = res.data.backgroundImageUrl || deImg;
          this.setBgImg(backImg)
        } else {
          this.setBgImg(deImg)
        }
      }).catch(() => {
        this.setBgImg(deImg)
      })
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth; // 获取浏览器的宽度
        if (winWidth > 850) {
          bodyStyle.background = "url(" + img + ") #b4daef no-repeat";
          bodyStyle.backgroundAttachment = "fixed";
        } else {
          bodyStyle.background = "#ffffff";
        }
      });
    },
    async getBackType(oid) {
      window.localStorage.setItem('openId', oid)
      let res = await get("/api/feedback/allFeedbackType");
      this.typeList = res.data
      if (res) {
        this.getShops()
      }
    },
    getShops() {
      get("/api/shop/queryAllShop").then(res => {
        let arr = res.data;
        let newArr = [];
        if (arr.length > 0) {
          newArr = arr.filter(i => i.shopList.length > 0);
          this.shopList = newArr;
          console.log(newArr);
        }
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    backHome() {
      this.$router.replace({
        path: "/Index",
      })
    },
    coverImgListBeforeUpload(file) {
      let index = file.name.lastIndexOf('.')
      if (index > -1) {
        let ext = file.name.substr(index + 1)
        if (ext) {
          ext = ext.toLowerCase()
          if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
            if (file.size / 1024 > 2048) {
              this.$message.warning('图片必须小于 2M!')
              return false
            } else {
              return true
            }
          }
        }
      } else {
        this.$message.warning('仅支持png，jpg，jpeg')
        return false
      }
    },
    coverImgListOnSuccess(data) {
      this.fileList.push({
        uid: this.coverImgCount++,
        name: 'image/jpeg',
        status: 'done',
        url: data.data
      })
    },
    coverImgListChange({file}) {
      if (file.status === 'removed') {
        postFrom("/file/delFile", {filePath: file.url}).then(() => {
          this.fileList = this.fileList.filter(item => item.uid !== file.uid)
        });
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    changePage() {
      this.subPage = !this.subPage
      this.$refs['form'].resetFields()
      this.$refs['form1'].resetFields()
      this.fileList = []
      if (!this.subPage) {
        this.recordList = []
        // this.getMyrecord()
      }
    },
    onSubmit1() {
      let array = this.form1;
      if (array.tel === "") {
        this.$message.warn('请输入要查询的手机号')
        return
      }
      this.getMyrecord()
    },
    async getMyrecord() {
      let param = {
        tel: this.form1.tel,
        // openId: window.localStorage.getItem('openId'),
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.pageNum,
        merchantId: process.env.VUE_APP_MerId
      }
      await get("/api/feedback/getMyFeedback", param).then(res => {
        let datas = res.data.rows;
        if (datas.length > 0) {
          datas.forEach(i => {
            if (i.reply) {
              // console.log(i)
            }
            if (i.attachment && i.attachment !== '{}' && i.attachment !== 'null') {
              i.attachment = JSON.parse(i.attachment)
              i.havImg = true
            } else {
              i.havImg = false
            }
          })
        } else {
          this.$message.info('暂无数据')
        }
        this.recordList = datas;
        this.total = res.data.total;
      })
    },
    // 监听页码条数发生改变的事件处理程序
    handleSizeChange(pageSize) {
      this.pageData.pageSize = pageSize;
      this.pageData.pageNum = 1;
      this.getMyrecord();
    },
    // 监听页码发生改变得方法
    handleCurrentChange(page) {
      this.pageData.pageNum = page;
      this.getMyrecord();
    },
    handlePreview1(record) {
      this.previewImage = record
      this.previewVisible = true;
    },
    onSubmit() {
      let array = this.form;
      // if (array.shopId === "") {
      //   this.$message.warn('请选择反馈门店')
      //   return
      // }
      // if (array.feedbackTypeId === "") {
      //   this.$message.warn('请选择反馈类型')
      //   return
      // }
      // if (array.name === "") {
      //   this.$message.warn('请填写姓名')
      //   return
      // }
      if (array.tel === "") {
        this.$message.warn('请填写手机号')
        return
      }
      if (array.content === "") {
        this.$message.warn('请填写意见')
        return
      }
      let imgs = this.fileList;
      let newParms = {
        content: array.content,
        shopId: array.shopId,
        tel: array.tel,
        merchantId: process.env.VUE_APP_MerId
      };
      let upImg = [];
      if (imgs.length > 0) {
        imgs.forEach(i => {
          upImg = upImg.concat(i.url)
        })
        newParms.attachment = {}
        newParms.attachment.imgList = upImg;
      } else {
        newParms.attachment = null
      }
      // if (window.localStorage.getItem('openId') !== 'undefined') {
      //   newParms.openId = window.localStorage.getItem('openId');
      // } else {
      //   newParms.openId = ''
      // }
      postJson("/api/feedback/sendFeedback", newParms).then(res => {
        if (res.result) {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs['form'].resetFields()
            this.fileList = []
          }, 500)
        }
      });
    }
  }
}
</script>

<style scoped>
.bodyBox {
  width: 92%;
  margin: 0 auto;
}

.topBox {
  line-height: 60px;
  font-size: 18px;
  width: 89%;
  margin: 0 auto;
  text-align: center;
  color: #000000;
}

.linkBox {
  width: 88%;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;
  background-color: #fafafa;
  text-indent: 20px;
  border-radius: 8px;

  .linkBoxItem {
    height: 38px;
    font-size: 16px;
    font-weight: bold;
  }

  .linkBoxItem::after {
    content: "/";
    font-size: 20px;
    margin-left: 15px;
    color: #666;
    cursor: unset;
    font-weight: normal;
  }

  .linkBoxItem:last-child:after {
    color: #fff;
  }
}

.listBox {
  min-height: 115px;
  width: 94%;
  margin: 25px auto;
  border-bottom: 1px solid #f1f1f1;
}

.listBox_item {
  line-height: 35px;
  font-size: 15px;
}

.listBox_imges {
  display: flex;
  align-items: center;
}

.imgBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}

.imgBox_img {
  width: 130px;
  height: 130px;
}

.formBox {
  width: 85%;
  margin: 10px auto;
}

.formBox1 {
  width: 100%;
  margin: 10px auto;
}

.subBtnBox {
  margin: 0 auto;
}

.subBtnBox_btn {
  width: 100%;
  height: 47px;
  font-size: 17px;
}

.headBox {
  height: 50px;
  line-height: 73px;
  text-align: right;
  font-size: 18px;
  color: #34A1F0;
  width: 87%;
  margin: 0 auto;
  display: flex;
  justify-content: end;
}

.headBox-p {
  height: 100%;
  width: 18%;
}

.headBox-p:hover {
  cursor: pointer;
}

.backBox {
  background-color: #0189ec;
  color: #FFFFFF;
  line-height: 49px;
  text-align: center;
  margin: 6px auto;
  width: 89%;
  border-radius: 14px;
  position: relative;
  top: 15px;
}

.backBox:hover {
  cursor: pointer;
}

.footBox {
  height: 100px;
  width: 87%;
  margin: 0 auto;
}

.nullTip {
  width: 100%;
  text-align: center;
  line-height: 180px;
}

@media only screen and (max-width: 1000px) {
  .headBox {
    font-size: 15px;
  }

  .headBox-p {
    width: 23%;
  }

  .bodyBox {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 0;
  }

  .topBox {
    font-size: 18px;
    padding-top: 5px;
    line-height: 30px;
  }

  .imgBox {
    padding-top: 30px;
  }

  .imgBox_img {
    width: 100px;
    height: 100px;
  }

  .formBox {
    width: 85%;
    padding-bottom: 44px;
  }

  .formBox1 {
    width: 100%;
    padding: 30px;
  }

  .subBtnBox {
    margin: 0 auto;
    text-align: center;
  }

  .subBtnBox_btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
  }
}

/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .MarBox {
    max-width: 580px;
    min-width: 360px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    position: relative;
  }

  .linkBoxItem:hover {
    cursor: pointer;
    color: #0189ec;
  }
}
</style>
